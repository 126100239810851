import { OpinionContent } from "../pages/admin/Opinion/OpinionRegister";
import adminInstance from "./adminInstance";

interface Account {
  identifier: string;
  password: string;
}

export const tryLogin = async (account: Account) => {
  const response = await adminInstance.post(`/account/sign-in`, account);

  return response;
};

export const getPeopleList = async (position: string, name?: string) => {
  const response = await adminInstance.get(`/lawyer?position=${position}`);

  return response;
};

export const getPeopleCount = async () => {
  const response = await adminInstance.get(`/lawyer/stats`);

  return response;
};

export const getPeopleItem = async (id: any) => {
  const response = await adminInstance.get(`/lawyer/${id}`);

  return response;
};

export const deletePeople = async (id: number) => {
  const response = await adminInstance.delete(`/lawyer/${id}`);

  return response;
};

export const createPeople = async (data: any) => {
  const response = await adminInstance.post(`/lawyer`, data);

  return response;
};

export const updatePeople = async (id: string | undefined, data: any) => {
  const response = await adminInstance.put(`/lawyer/${id}`, data);

  return response;
};

export const createImage = async (file: any) => {
  const response = await adminInstance.post(`/images`, file);

  return response;
};

export const getWorkCategory = async (field: string) => {
  const response = await adminInstance.get(`/work-field?workField=${field}`);

  return response;
};

export const updateWorkCategory = async (field: string, data: any) => {
  const response = await adminInstance.patch(
    `/work-field?workFieldName=${field}`,
    data
  );

  return response;
};

export const updatePeopleOrder = async (data: number[]) => {
  const response = await adminInstance.put(`/lawyer/sort`, data);

  return response;
};

export const getPostList = async (
  page: number,
  category: string,
  searchValue?: string
) => {
  const response = await adminInstance.get(
    `/news?page=${page}&category=${category}&search=${searchValue}`
  );

  return response;
};

export const getPostCount = async () => {
  const response = await adminInstance.get(`/news/stats`);

  return response;
};

export const deletePost = async (id: number) => {
  const response = await adminInstance.delete(`/news/${id}`);

  return response;
};

export const createNews = async (data: any) => {
  const response = await adminInstance.post(`/news`, data);

  return response;
};

export const getNewsItem = async (id: number) => {
  const response = await adminInstance.get(`/news/${id}`);

  return response;
};

export const updateNewsItem = async (id: number, data: any) => {
  const response = await adminInstance.put(`/news/${id}`, data);

  return response;
};

export const getMediaCount = async () => {
  const response = await adminInstance.get(`/media/stats`);

  return response;
};

export const getMediaList = async (
  page: number,
  category: string,
  searchValue?: string
) => {
  const response = await adminInstance.get(
    `/media?page=${page}&category=${category}&search=${searchValue}`
  );

  return response;
};

export const getMediaItem = async (id: number) => {
  const response = await adminInstance.get(`/media/${id}`);

  return response;
};

export const getMediaWorkFields = async () => {
  const response = await adminInstance.get(`/media/work-fields`);

  return response;
};

export const getMediaLawyers = async () => {
  const response = await adminInstance.get(`/media/lawyers`);

  return response;
};

export const createMedia = async (data: any) => {
  const response = await adminInstance.post(`/media`, data);

  return response;
};

export const updateMedia = async (id: number, data: any) => {
  const response = await adminInstance.put(`/media/${id}`, data);

  return response;
};

export const deleteMedia = async (id: number) => {
  const response = await adminInstance.delete(`/media/${id}`);

  return response;
};

export const getNewsLetterList = async (
  page: number,
  category: string,
  searchValue?: string
) => {
  const response = await adminInstance.get(
    `/newsletter?page=${page}&category=${category}&search=${searchValue}`
  );

  return response;
};

export const getNewsLetterCount = async () => {
  const response = await adminInstance.get(`/newsletter/stats`);

  return response;
};

export const deleteNewsLetter = async (id: number) => {
  const response = await adminInstance.delete(`/newsletter/${id}`);

  return response;
};

export const createNewLetter = async (data: any) => {
  const response = await adminInstance.post(`/newsletter`, data);

  return response;
};

export const getNewsLetterItem = async (id: number) => {
  const response = await adminInstance.get(`/newsletter/${id}`);

  return response;
};

export const updateNewsLetterItem = async (id: number, data: any) => {
  const response = await adminInstance.put(`/newsletter/${id}`, data);

  return response;
};

export const getOpinionList = async (
  page: number,
  team: string,
  searchValue?: string
) => {
  const response = await adminInstance.get(
    `/opinion?page=${page}&team=${team}&search=${searchValue}`
  );

  return response;
};

export const getOpinionCount = async () => {
  const response = await adminInstance.get(`/opinion/stats`);

  return response;
};

export const createOpinion = async (data: OpinionContent) => {
  const response = await adminInstance.post(`/opinion`, data);

  return response;
};

export const updateOpinion = async (id: number, data: OpinionContent) => {
  const response = await adminInstance.put(`/opinion/${id}`, data);

  return response;
};

export const deleteOpinion = async (id: number) => {
  const response = await adminInstance.delete(`/opinion/${id}`);

  return response;
};

export const getOpinionItem = async (id: number) => {
  const response = await adminInstance.get(`/opinion/${id}`);

  return response;
};
