import more from "../../assets/images/more.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import img from "../../assets/images/img";
import { formatDateToDotSeparated } from "../../utils/dateFormat";
import { swiperPerViewCount } from "../../utils/swiperPerViewCount";
import { OpinionContent } from "../admin/Opinion/OpinionRegister";
import { getLandingPageOpinion } from "../../api/userOpinion";

const OpinionSection = () => {
  const [opinionData, setOpinionData] = useState<OpinionContent[]>([]);
  const [isOpinionLoading, setIsOpinionLoading] = useState(true);

  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const fetchOpinion = async () => {
      try {
        const data = await getLandingPageOpinion();
        setOpinionData(data);
        setIsOpinionLoading(false);
      } catch (error) {
        console.error("오피니언 조회 중 에러 발생:", error);
      }
    };

    fetchOpinion();
  }, []);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <section className="newsletter-section">
      <div className="title">
        <div>
          <p>
            <span className="blue">I</span>NFORMATION
            <Link to="/news?category=법률정보">
              <button className="more">
                <img src={more} alt="" />
              </button>
            </Link>
          </p>
        </div>
      </div>
      {!isOpinionLoading && (
        <div className="swiper-container">
          <button className="nav-button prev" onClick={handlePrev}>
            <img src={img.icons.ArrowLeft} alt="Previous Slide" />
          </button>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={1}
            spaceBetween={10}
            scrollbar={{ draggable: true, dragSize: 300 }}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            onSwiper={(swiper: any) => (swiperRef.current = swiper)}
            breakpoints={{
              300: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: swiperPerViewCount(opinionData.length, 4),
              },
            }}
          >
            {opinionData.length > 0 &&
              opinionData.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link to={item.link} target="_blank">
                    <div className="newsletter-content">
                      <div className="mark">
                        <span>{item.team}</span>
                      </div>
                      <div className="title">
                        <span>{item.title}</span>
                      </div>
                      <div className="bottom">
                        <span></span>
                        <span>
                          {formatDateToDotSeparated(item.articleDate)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
          <button className="nav-button next" onClick={handleNext}>
            <img src={img.icons.ArrowRight} alt="Next Slide" />
          </button>
        </div>
      )}
    </section>
  );
};

export default OpinionSection;
