import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const CenterSide: React.FC = () => {
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isWindows = navigator.userAgent.match(/windows/i);

  return (
    <div
      className="side-button-container"
      style={{
        left: isHovered ? "0px" : isWindows ? "-270px" : "-270px",
        marginTop: "30px",
      }}
      onMouseLeave={handleMouseLeave}
    >
      <div className="section1">
        <button className="side-button">
          <Link
            to="/support-center"
            onClick={pathname === "/support-center" ? scrollToTop : undefined}
          >
            기업 감사/내부통제 지원센터
          </Link>
        </button>

        <button className="side-button">
          <Link
            to="/tech-center"
            onClick={pathname === "/tech-center" ? scrollToTop : undefined}
          >
            기술 보호 센터
          </Link>
        </button>

        <button className="side-button">
          <Link
            to="/property-center"
            onClick={pathname === "/property-center" ? scrollToTop : undefined}
          >
            건설/부동산 센터
          </Link>
        </button>
      </div>
      <div className="section2" onMouseEnter={handleMouseEnter}>
        <div>CENTER</div>
      </div>
    </div>
  );
};

export default CenterSide;
