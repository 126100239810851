import { useEffect, useState } from "react";
import home from "../../assets/images/icons/home.png";
import { Link, useLocation } from "react-router-dom";
import img from "../../assets/images/img";
import {
  getMediaNewsData,
  getNewsData,
  getOpinionData,
} from "../../api/getNewsData";
import { useNewsStore } from "../../stores/newsStore";
import { useMediaQuery } from "react-responsive";
import useUpdateUrlWithQuery from "../../utils/useUpdateUrlWithQuery";
import { formatDateToDotSeparated } from "../../utils/dateFormat";

interface News {
  totalCount: number;
  newsList: {
    id: number;
    title: string;
    mainImg: string;
    createdAt: string;
  }[];
}

interface MediaNews {
  totalCount: number;
  mediaList: {
    id: number;
    title: string;
    link: string;
    work_field: string | null;
    media_company: string;
    mediaDate: string;
  }[];
}

interface Opinion {
  totalCount: number;
  opinionList: {
    id: number;
    title: string;
    link: string;
    team: string;
    articleDate: string;
  }[];
}

const NewsPage = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 1403px)" });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [newsData, setNewsData] = useState<News>({
    totalCount: 0,
    newsList: [],
  });

  const [mediaNewsData, setMediaNewsData] = useState<MediaNews>({
    totalCount: 0,
    mediaList: [],
  });

  const [opinionData, setOpinionData] = useState<Opinion>({
    totalCount: 0,
    opinionList: [],
  });

  const category = new URLSearchParams(useLocation().search).get("category");
  const [selectedTab, setSelectedTab] = useState(category || "언론보도");
  const setSelectedCurrentTab = useNewsStore((state) => state.setSelectedTab);
  const setSelectedNewsId = useNewsStore((state) => state.setSelectedNewsId);
  const isMediaNews = selectedTab === "언론보도";
  const isOpinion = selectedTab === "법률정보";
  const isNews = selectedTab === "Lawvax소식" || selectedTab === "인재영입";
  const newDataLimit = 6;
  const mediaDataLimit = 10;
  const itemsPerPage = isMediaNews ? mediaDataLimit : newDataLimit;
  const pagesPerGroup = 5;
  const totalPages = Math.ceil(
    isMediaNews
      ? mediaNewsData.totalCount / itemsPerPage
      : isOpinion
        ? opinionData.totalCount / itemsPerPage
        : newsData.totalCount / itemsPerPage
  );
  const [pageGroup, setPageGroup] = useState(1);
  const startPage = (pageGroup - 1) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

  const updateUrlWithQuery = useUpdateUrlWithQuery();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        if (isMediaNews) {
          const data = await getMediaNewsData(
            currentPage,
            mediaDataLimit,
            searchTerm
          );
          setMediaNewsData({
            totalCount: data.totalCount,
            mediaList: data.mediaList,
          });
        }

        if (isOpinion) {
          const data = await getOpinionData(
            currentPage,
            mediaDataLimit,
            searchTerm
          );
          setOpinionData({
            totalCount: data.totalCount,
            opinionList: data.opinionList,
          });
        }

        if (isNews) {
          const data = await getNewsData(
            currentPage,
            selectedTab,
            newDataLimit,
            searchTerm
          );
          setNewsData({
            totalCount: data.totalCount,
            newsList: data.newsList,
          });
        }

        if (searchTerm !== "") {
          updateUrlWithQuery(
            [
              { paramsKey: "searchTerm", paramsValue: searchTerm },
              { paramsKey: "category", paramsValue: selectedTab },
            ],
            true
          );
        }
      } catch (error) {
        console.error("뉴스 페이지 조회 중 에러 발생:", error);
      }
    };

    fetchNews();
  }, [currentPage, selectedTab, searchTerm]);

  const handleClickTab = (tab: string) => {
    setSelectedTab(tab);
    setSelectedCurrentTab(tab);
    setCurrentPage(1);
    setPageGroup(1);
  };

  const handleSelectNews = (id: number) => {
    setSelectedNewsId(id);
  };

  const handleClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePrevGroupClick = () => {
    if (pageGroup > 1) {
      setPageGroup(pageGroup - 1);
      setCurrentPage((pageGroup - 2) * pagesPerGroup + 1);
    }
  };

  const handleNextGroupClick = () => {
    if (endPage < totalPages) {
      setPageGroup(pageGroup + 1);
      setCurrentPage(pageGroup * pagesPerGroup + 1);
    }
  };

  const handleFirstClick = () => {
    setCurrentPage(1);
    setPageGroup(1);
  };

  const handleLastClick = () => {
    setCurrentPage(totalPages);
    setPageGroup(Math.ceil(totalPages / pagesPerGroup));
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    setSearchTerm(formData.get("searchTerm") as string);
    setCurrentPage(1);
    setPageGroup(1);
  };

  const renderNewsGrid = () => {
    let targetLength = 0;

    if (isMediaNews) targetLength = mediaNewsData.mediaList.length;
    if (isOpinion) targetLength = opinionData.opinionList.length;
    if (isNews) targetLength = newsData.newsList.length;

    if (!targetLength) {
      return (
        <div className="news-grid">
          <div>뉴스가 없습니다.</div>
        </div>
      );
    }

    if (isMediaNews) {
      return (
        <div className="press-grid">
          {mediaNewsData?.mediaList.map((news, index) => (
            <Link
              to={news.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleSelectNews(news.id)}
            >
              <div className="press-item" key={index}>
                {news.work_field ? <label>{news.work_field}</label> : <div />}
                <h2>{news.title}</h2>
                <div className="bottom">
                  <span className="news-date">{news.media_company}</span>
                  <span className="news-date">
                    {formatDateToDotSeparated(news.mediaDate)}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      );
    }

    if (isOpinion) {
      return (
        <div className="press-grid">
          {opinionData?.opinionList.map((opinion, index) => (
            <Link
              to={opinion.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleSelectNews(opinion.id)}
            >
              <div className="press-item" key={index}>
                {opinion.team ? <label>{opinion.team}</label> : <div />}
                <h2>{opinion.title}</h2>
                <div className="bottom">
                  <span className="news-date"></span>
                  <span className="news-date">
                    {formatDateToDotSeparated(opinion.articleDate)}
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      );
    }

    return (
      <div className="news-grid">
        {newsData?.newsList.map((news, index) => (
          <div className="news-item" key={index}>
            <Link
              to={`/news/post/${news.id}`}
              onClick={() => handleSelectNews(news.id)}
            >
              <img
                src={
                  news.mainImg === "null" || news.mainImg === null
                    ? selectedTab === "LawVax소식"
                      ? img.defaultNews
                      : selectedTab === "인재영입"
                        ? img.defaultComer
                        : img.defaultNews
                    : news.mainImg
                }
                alt={news.title}
              />
            </Link>

            <div className="news-content">
              <div className="news-wrapper">
                <Link
                  to={`/news/post/${news.id}`}
                  onClick={() => handleSelectNews(news.id)}
                >
                  <p className="news-title">{news.title}</p>
                </Link>

                <div className="bottom">
                  <span className="news-date" />
                  <span className="news-date">
                    {formatDateToDotSeparated(news.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="news-page">
      {isDesktop && (
        <div className="process">
          <img src={home} alt="home" />
          <span>HOME</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span>법인소식</span>
          <span>
            <img src={img.icons.grayRight} alt="" />
          </span>
          <span className="search">{selectedTab}</span>
        </div>
      )}
      <div className="title">
        <div>
          <p>
            <span className="blue">N</span>
            EWS
          </p>
        </div>
      </div>
      <div className="theme-select">
        <div className="theme-select-wrap">
          <button onClick={() => handleClickTab("언론보도")}>
            <span className={selectedTab === "언론보도" ? "active" : ""}>
              언론보도
            </span>
          </button>
          <button onClick={() => handleClickTab("Lawvax소식")}>
            <span className={selectedTab === "Lawvax소식" ? "active" : ""}>
              LawVax소식
            </span>
          </button>
          <button onClick={() => handleClickTab("인재영입")}>
            <span className={selectedTab === "인재영입" ? "active" : ""}>
              인재영입
            </span>
          </button>
          <button onClick={() => handleClickTab("법률정보")}>
            <span className={selectedTab === "법률정보" ? "active" : ""}>
              법률정보
            </span>
          </button>
        </div>
        <form className="search-bar" onSubmit={handleSearch}>
          <input
            name="searchTerm"
            type="text"
            placeholder="제목을 검색해주세요."
            className="search-input"
            defaultValue=""
          />
          <button type="submit" style={{ bottom: "33px" }}>
            <img
              className="search-icon"
              src={img.icons.search}
              alt="search-icon"
            />
          </button>
        </form>
      </div>
      {renderNewsGrid()}
      <div className="pagination">
        <img
          src={img.icons.left02}
          alt="First Page"
          onClick={handleFirstClick}
          style={{ cursor: currentPage > 1 ? "pointer" : "not-allowed" }}
        />
        <img
          src={img.icons.left}
          alt="Previous Group"
          onClick={handlePrevGroupClick}
          style={{ cursor: pageGroup > 1 ? "pointer" : "not-allowed" }}
        />
        <div className="number-wrap">
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
            <span key={index}>
              <span
                className={`page-number ${currentPage === startPage + index ? "active" : ""}`}
                onClick={() => handleClick(startPage + index)}
              >
                {startPage + index}
              </span>
              {index < endPage - startPage && (
                <span className="separator">|</span>
              )}
            </span>
          ))}
        </div>

        <img
          src={img.icons.right}
          alt="Next Group"
          onClick={handleNextGroupClick}
          style={{ cursor: endPage < totalPages ? "pointer" : "not-allowed" }}
        />
        <img
          src={img.icons.right02}
          alt="Last Page"
          onClick={handleLastClick}
          style={{
            cursor: currentPage < totalPages ? "pointer" : "not-allowed",
          }}
        />
      </div>
    </div>
  );
};

export default NewsPage;
