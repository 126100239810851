import { useEffect, useRef, useState } from "react";
import img from "../../assets/images/img";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getCenterMembers } from "../../api/getCenterMembers";
import { getCenterNewsLetter } from "../../api/getCenterNewsLetter";
import { formatDateToDotSeparated } from "../../utils/dateFormat";
import { swiperPerViewCount } from "../../utils/swiperPerViewCount";

interface MediaItems {
  id: number;
  title: string;
  work_field: string;
  link: string;
  media_company: string;
  mediaDate: string;
}

const PropertyCenter = () => {
  const [activeTab, setActiveTab] = useState("법률센터 강점");
  const [showAll, setShowAll] = useState(false);
  const [membersData, setMembersData] = useState<any>([]);
  const [mediaData, setMediaData] = useState<MediaItems[]>([]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const swiperRef = useRef<any>(null);

  // eslint-disable-next-line
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1400px)" });
  const isResponsive = useMediaQuery({ query: "(max-width: 1760px)" });

  const fetchMembers = async () => {
    try {
      const response = await getCenterMembers("건설/부동산 센터", isFirstPage);
      setMembersData(response.lawyerList);
      setShowAll(response.hasNextPage);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMedia = async () => {
    try {
      const response = await getCenterNewsLetter("건설/부동산 센터");
      setMediaData(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMembers();
    fetchMedia();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line
  }, [isFirstPage]);

  const handleToggle = () => {
    setShowAll((prevState) => !prevState);
    setIsFirstPage((prevState) => !prevState);
  };

  const scrollToSection = (sectionId: any) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const sectionTop =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionTop - 150,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className="property-center-page">
        <div className="main">
          <div className="text-wrap">
            <p>Construction / Property</p>
            <h1>Center</h1>
          </div>
        </div>
      <div className="theme-select">
        <button
          className={activeTab === "법률센터 강점" ? "active" : ""}
          onClick={() => {
            setActiveTab("법률센터 강점");
            scrollToSection("section01");
          }}
        >
          법률센터 강점
        </button>
        <button
          className={activeTab === "주요 업무" ? "active" : ""}
          onClick={() => {
            setActiveTab("주요 업무");
            scrollToSection("section02");
          }}
        >
          주요 업무
        </button>
        <button
          className={activeTab === "구성원" ? "active" : ""}
          onClick={() => {
            setActiveTab("구성원");
            scrollToSection("section04");
          }}
        >
          구성원
        </button>
        <button
          className={activeTab === "언론보도" ? "active" : ""}
          onClick={() => {
            setActiveTab("언론보도");
            scrollToSection("section05");
          }}
        >
          언론보도
        </button>
      </div>

      {isDesktop ? (
        <>
          <h2 className="sub-title roman-title">Strength</h2>
          <div className="section01" id="section01">
            <div className="strength-section">
              <div className="strength-card">
                <h1>
                  법률리스크
                  <br /> 예방 및 대응에 특화
                </h1>
                <img.Card01 />
                <h3>
                  사업구조가 복잡해지고 이해관계가 첨예화 됨에 따라
                  <br /> 법률리스크가 다양화되고, 민 · 형사상 분쟁 급증.
                  <br /> 건설 · 부동산 사건 수사, 재판, 변론 경험이 축적된{" "}
                  <br /> 대표 변호사가 직접 리스크를 확인하고 대응
                </h3>
              </div>
              <div className="strength-card">
                <h1>
                  건설 부동산
                  <br /> 원스톱 서비스 제공
                </h1>
                <img.Card02 />
                <h3>
                  여러 건의 부동산 개발 사업을 직접 수행한
                  <br /> 부장검사 출신 변호사가 팀장으로서
                  <br /> PM 전담 팀 운영
                  <br />
                  <br />
                </h3>
              </div>
              <div className="strength-card">
                <h1>
                  건설 부동산 분야
                  <br /> 전문가 그룹 조력
                </h1>
                <img.Card03 />
                <h3>
                  부동산 금융법 교수 (금융구조 자문), 개발프로젝트
                  <br /> 건축사 (건축설계와 인허가 관리업무, 감리업무),
                  <br /> PM 전문가 등 업계 최고의 전문가 그룹이 주축이
                  <br /> 되어 사업 및 금융구조 설계 자문
                </h3>
              </div>
            </div>
            <div className="img-wrap">
              <img src={img.CenterMain02} alt="" />
            </div>
          </div>

          <h2 className="sub-title roman-title">Main Tasks</h2>
          <div className="section02" id="section02">
            <div className="content">
              <div className="text-wrap">
                <h3 className="h3">민사 · 형사 · 행정 · 송무</h3>
                <p className="p">
                  공사 하자, 지체상금, 건설사고, 재건축 · 재개발 관련 분쟁,
                  <br /> 횡령 · 배임 등 다양한 분쟁들에 대한 최적의 솔루션 제공
                </p>
              </div>
              <div className="img-wrap01">
                <img src={img.property01} alt="" />
              </div>
            </div>
            <div
              className="content"
              style={{
                flexDirection: isResponsive ? "column" : "row",
              }}
            >
              <div className="img-wrap02">
                <img src={img.property02} alt="" />
              </div>
              <div className="text-wrap">
                <h3 className="h3">건설 · 부동산 법률자문</h3>
                <p className="p">
                  사업구조 설계, 사업 제안, 입찰, 우선협상대상자 선정, 사업협약
                  <br />및 주주협약 체결 등 전문적인 검토를 통해 법률 리스크
                  예방
                </p>
              </div>
            </div>
            <div className="content" style={{ alignItems: "flex-end" }}>
              <div className="text-wrap">
                <h3 className="h3">부동산 개발 사업 PM 업무</h3>
                <p className="p">
                  법률 자문 외 PM 업무를 함께 수행하여 ONE-STOP 서비스제공.
                  <br /> LawVax는 실제 부동산 개발 사업을 수행한 부장검사 출신
                  변호사를
                  <br /> 필두로 한 PM 전담 팀 구성
                </p>
              </div>
              <div className="img-wrap03">
                <img src={img.property03} alt="" />
              </div>
            </div>
            <div className="content" style={{ flexDirection: "column" }}>
              <div className="img-wrap04">
                <img src={img.property04} alt="" />
              </div>
              <h3 className="h3" style={{ marginTop: "30px" }}>
                LawVax 법률센터 주요 수행 업무
              </h3>
              <div className="text-direction">
                <div className="text-wrap">
                  <ul className="text-ul">
                    <li>
                      태광그룹 건설계열사 임직원의 부동산 PF 비위 관련 내부감사
                    </li>
                    <li>
                      은평 000 지주택 조합 탈퇴 및 분담금반환청구 소송 수행
                    </li>
                    <li>
                      군자주공 재건축 추진준비위원회 신탁방식 재건축 법률자문
                    </li>
                    <li>
                      LIG 건영아파트 공사도급계약 해제 및 손해배상 청구 사건
                      <br /> 분당 000 00000, 송도 0000 건축설계, 인허가
                    </li>
                  </ul>
                </div>
                <div className="text-wrap">
                  <ul className="text-ul">
                    <li>황학동 지역주택조합 PF 무산 관련 사건</li>
                    <li>
                      SG 타워 신축공사 시행사 및 시행사 상대 공사중지가처분
                    </li>
                    <li>
                      오산, 양주, 당산, 김포, 경주 등 부동산 개발사업 PM 수행
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h2 className="sub-title roman-title">Strength</h2>
          <div className="section01" id="section01">
            <div className="strength-section">
              <div className="strength-card">
                <h1>
                  법률리스크
                  <br /> 예방 및 대응에 특화
                </h1>
                <img.Card01 />
                <h3>
                  사업구조가 복잡해지고 이해관계가 첨예화 됨에 따라
                  <br /> 법률리스크가 다양화되고, 민 · 형사상 분쟁 급증.
                  <br /> 건설 · 부동산 사건 수사, 재판, 변론 경험이 축적된{" "}
                  <br /> 대표 변호사가 직접 리스크를 확인하고 대응
                </h3>
              </div>
              <div className="strength-card">
                <h1>
                  건설 부동산
                  <br /> 원스톱 서비스 제공
                </h1>
                <img.Card02 />
                <h3>
                  여러 건의 부동산 개발 사업을 직접 수행한
                  <br /> 부장검사 출신 변호사가 팀장으로서
                  <br /> PM 전담 팀 운영
                  <br />
                  <br />
                </h3>
              </div>
              <div className="strength-card">
                <h1>
                  건설 부동산 분야
                  <br /> 전문가 그룹 조력
                </h1>
                <img.Card03 />
                <h3>
                  부동산 금융법 교수 (금융구조 자문), 개발프로젝트
                  <br /> 건축사 (건축설계와 인허가 관리업무, 감리업무),
                  <br /> PM 전문가 등 업계 최고의 전문가 그룹이 주축이
                  <br /> 되어 사업 및 금융구조 설계 자문
                </h3>
              </div>
            </div>
            <div className="img-wrap">
              <img src={img.CenterMain02} alt="" />
            </div>
          </div>

          <h2 className="sub-title roman-title">Main Tasks</h2>
          <div className="section02" id="section02">
            <div className="content">
              <div className="text-wrap">
                <h3 className="h3">민사 · 형사 · 행정 · 송무</h3>
                <p className="p">
                  공사 하자, 지체상금, 건설사고, 재건축 · 재개발 관련 분쟁,
                  <br /> 횡령 · 배임 등 다양한 분쟁들에 대한 최적의 솔루션 제공
                </p>
              </div>
              <div className="img-wrap01">
                <img src={img.property01} alt="" />
              </div>
            </div>
            <div
              className="content"
              style={{
                flexDirection: isResponsive ? "column" : "row",
              }}
            >
              <div className="img-wrap02">
                <img src={img.property02} alt="" />
              </div>
              <div className="text-wrap">
                <h3 className="h3">건설 · 부동산 법률자문</h3>
                <p className="p">
                  사업구조 설계, 사업 제안, 입찰, 우선협상대상자 선정,
                  <br />
                  사업협약 및 주주협약 체결 등 전문적인 검토를 통해 법률
                  <br />
                  리스크 예방
                </p>
              </div>
            </div>
            <div className="content" style={{ alignItems: "flex-end" }}>
              <div className="text-wrap">
                <h3 className="h3">부동산 개발 사업 PM 업무</h3>
                <p className="p">
                  법률 자문 외 PM 업무를 함께 수행하여 ONE-STOP 서비스
                  <br />
                  제공. LawVax는 실제 부동산 개발 사업을 수행한 부장검사
                  <br />
                  출신 변호사를 필두로 한 PM 전담 팀 구성
                </p>
              </div>
              <div className="img-wrap03">
                <img src={img.property03} alt="" />
              </div>
            </div>
            <div className="content" style={{ flexDirection: "column" }}>
              <div className="img-wrap04">
                <img src={img.property04} alt="" />
              </div>
              <h3 className="h3" style={{ marginTop: "30px" }}>
                LawVax 법률센터 주요 수행 업무
              </h3>
              <div className="text-direction">
                <div className="text-wrap">
                  <ul className="text-ul">
                    <li>
                      태광그룹 건설계열사 임직원의 부동산 PF 비위 관련
                      <br /> 내부감사
                    </li>
                    <li>
                      은평 000 지주택 조합 탈퇴 및 분담금반환청구 소송 수행
                    </li>
                    <li>
                      군자주공 재건축 추진준비위원회 신탁방식 재건축
                      <br /> 법률자문
                    </li>
                    <li>
                      LIG 건영아파트 공사도급계약 해제 및 손해배상 청구 사건
                      <br /> 분당 000 00000, 송도 0000 건축설계, 인허가
                    </li>
                  </ul>
                </div>
                <div className="text-wrap">
                  <ul className="text-ul">
                    <li>황학동 지역주택조합 PF 무산 관련 사건</li>
                    <li>
                      SG 타워 신축공사 시행사 및 시행사 상대 공사중지가처분
                    </li>
                    <li>
                      오산, 양주, 당산, 김포, 경주 등 부동산 개발사업 PM 수행
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {membersData.length > 0 && (
        <>
          <h2 className="sub-title roman-title">Our Team</h2>
          <div className="section04" id="section04">
            <div className="members-wrap">
              {membersData?.map((lawyer: any, index: number) => (
                <div key={index} className="lawyer-item">
                  <Link to={`/members/profile/${lawyer.id}`}>
                    <img src={lawyer.mainImg} alt={lawyer.nameKo} />
                  </Link>
                  <div className="text-wrap">
                    <p className="p1">
                      <span>{lawyer.nameKo}</span>
                    </p>
                    <p className="p2">{lawyer.position}</p>
                    <p
                      className="p2"
                      style={{ display: "block", marginLeft: "0px" }}
                    >
                      {lawyer.email}
                    </p>
                    <p className="class-wrap">
                      {lawyer.firstMainCareer && (
                        <p className="p3">{lawyer.firstMainCareer}</p>
                      )}
                      {lawyer.secondMainCareer && (
                        <p className="p3">{lawyer.secondMainCareer}</p>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {showAll && isFirstPage && (
              <button className="toggle-button" onClick={handleToggle}>
                더보기
                <img src={img.icons.down} alt="" />
              </button>
            )}
            {!showAll && !isFirstPage && (
              <button className="toggle-button" onClick={handleToggle}>
                접기
                <img src={img.icons.up} alt="" />
              </button>
            )}
          </div>
        </>
      )}
      {mediaData?.length > 0 && (
        <div className="section05" id="section05">
          <div className="sub-title">
            <p>
              언론보도
              <Link to="/news">
                <button className="more">
                  <img src={img.more} alt="" />
                </button>
              </Link>
            </p>
          </div>
          <div className="swiper-container">
            {!isTablet && (
              <button className="nav-button prev" onClick={handlePrev}>
                <img src={img.icons.ArrowLeft} alt="Previous Slide" />
              </button>
            )}
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              spaceBetween={10}
              scrollbar={{ draggable: true }}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              onSwiper={(swiper: any) => (swiperRef.current = swiper)}
              breakpoints={{
                320: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                768: {
                  slidesPerView: swiperPerViewCount(mediaData.length, 4),
                  spaceBetween: 5,
                },
              }}
            >
              {mediaData?.map((item: MediaItems, index: number) => (
                <SwiperSlide key={index}>
                  <Link
                    to={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="newsletter-content">
                      <div className="mark">
                        <span>{item.work_field}</span>
                      </div>
                      <div className="title">
                        <span>{item.title}</span>
                      </div>
                      <div className="bottom">
                        <span className="date">{item.media_company}</span>
                        <span className="date">
                          {formatDateToDotSeparated(item.mediaDate)}
                        </span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            {!isTablet && (
              <button className="nav-button next" onClick={handleNext}>
                <img src={img.icons.ArrowRight} alt="Next Slide" />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyCenter;
