/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import img from "../assets/images/img";
import logo from "../assets/images/HeaderLogo.png";

function HeaderMob() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMainPage = location.pathname === "/";
  const [isNavVisible, setNavVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleRefresh = () => {
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/", { replace: true });
    }
  };

  const toggleNav = () => {
    setNavVisible(!isNavVisible);
  };

  const closeNav = () => {
    setNavVisible(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    if (isMainPage) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMainPage]);

  const handleNavigateToMap = () => {
    navigate("/", { state: { scrollToMap: true } });
  };

  const handleNavigateToCall = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-mob">
      <div css={header_wrap(isScrolled, isMainPage)}>
        <div className="header-section logo">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="logo-image"
              onClick={handleRefresh}
            />
          </Link>
        </div>
        {isNavVisible ? (
          <div css={btn_container}>
            <button css={close_btn} onClick={closeNav}>
              <img src={img.icons.closeBtn} alt="닫기 버튼" />
            </button>
          </div>
        ) : (
          <div css={side_btn} onClick={toggleNav}>
            <span css={line}></span>
            <span css={line}></span>
            <span css={line}></span>
          </div>
        )}
      </div>

      <nav css={nav_style(isNavVisible)}>
        <ul css={link_1}>
          <li>
            <Link to="/" onClick={closeNav} css={menuItemWrap}>
              Home
              <img css={menuGrayRight} src={img.icons.menuGrayRight} alt="" />
            </Link>
          </li>
          <li>
            <Link to="/intro" onClick={closeNav} css={menuItemWrap}>
              LawVax
              <img css={menuGrayRight} src={img.icons.menuGrayRight} alt="" />
            </Link>
          </li>
          <li>
            <Link to="/workfield" onClick={closeNav} css={menuItemWrap}>
              업무분야
              <img css={menuGrayRight} src={img.icons.menuGrayRight} alt="" />
            </Link>
          </li>
          <li>
            <Link to="/members" onClick={closeNav} css={menuItemWrap}>
              구성원
              <img css={menuGrayRight} src={img.icons.menuGrayRight} alt="" />
            </Link>
          </li>
          <li>
            <Link to="/news" onClick={closeNav} css={menuItemWrap}>
              법인소식
              <img css={menuGrayRight} src={img.icons.menuGrayRight} alt="" />
            </Link>
          </li>
          <li>
            <Link
              to="https://lawvax.stibee.com"
              target="_blank"
              onClick={closeNav}
              css={menuItemWrap}
            >
              뉴스레터
              <img css={menuGrayRight} src={img.icons.menuGrayRight} alt="" />
            </Link>
          </li>
        </ul>

        <hr />

        <ul css={link_2}>
          <Link to="/support-center" onClick={closeNav}>
            <li>기업 감사/내부통제 지원센터</li>
          </Link>
          <Link to="/tech-center" onClick={closeNav}>
            <li>기술보호센터</li>
          </Link>
          <Link to="/property-center" onClick={closeNav}>
            <li>건설/부동산 센터</li>
          </Link>
        </ul>

        <hr />

        <ul css={link_3}>
          <li
            onClick={() => {
              handleNavigateToMap();
              closeNav();
            }}
          >
            오시는 길
          </li>
          <li
            onClick={() => {
              handleNavigateToCall();
              closeNav();
            }}
          >
            연락처
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default HeaderMob;

const header_wrap = (isScrolled: boolean, isMainPage: boolean) => css`
  height: 96px;
  position: fixed;
  top: 0;
  display: flex;
  z-index: 1300;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  height: 62px;
  box-shadow: 0px 6px 33px 0px #ebebeb;

  transform: ${isMainPage
    ? isScrolled
      ? "translateY(0)"
      : "translateY(-150%)"
    : "translateY(0)"};
  transition: transform 0.3s ease-in-out;
`;

const side_btn = css`
  width: 18px;
  height: 48px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const line = css`
  width: 18px;
  height: 2px;
  background-color: #111;
  margin: 2px 0;
  border-radius: 2px;
`;

const nav_style = (isNavVisible: any) => css`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--mono-white);
  padding: 102px 0px 0px 0px;

  transform: ${isNavVisible ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;

  justify-content: flex-start;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 1200;

  hr {
    border: 1px solid var(--mono-gray-line-1);
    margin: 0;
  }

  li,
  span {
    cursor: pointer;
  }

  @media (min-width: 1280px) {
    display: ${isNavVisible ? "block" : "none"};
  }
`;

const btn_container = css`
  margin-right: 20px;
  & img {
    width: 24px;
  }
`;

const close_btn = css`
  width: 24px;
  height: 24px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

const link_1 = css`
  padding-left: 40px;
  padding-bottom: 40px;
  color: var(--mono-gray-txt-dark);
  display: flex;
  flex-direction: column;
  gap: 30px;

  li {
    font-size: 24px;
    font-weight: 500;

    a {
      font-weight: 500;
      text-decoration: none;
      color: #222;
    }
  }
`;

const link_2 = css`
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  li {
    color: #005bac;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }
`;

const link_3 = css`
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  li {
    color: #666666;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
  }
`;

const menuItemWrap = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
`;

const menuGrayRight = css`
  width: 40px;
  height: 40px;
`;
