import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import img from "../../assets/images/img";
import { getNewsLandingPage } from "../../api/newsLandingPage";
import { useNewsStore } from "../../stores/newsStore";

const NewsSection = () => {
  const [activeTab, setActiveTab] = useState("Lawvax소식");
  const [currentIndex, setCurrentIndex] = useState(0); // 초기 index 상태
  const [fadeState, setFadeState] = useState(""); // 페이드 애니메이션 상태
  interface NewsItem {
    title: string;
    summary: string;
    mainImg: string;
    id: number;
  }

  const [newsData, setNewsData] = useState<NewsItem[]>([]);
  const setSelectedTab = useNewsStore((state) => state.setSelectedTab);
  const selectedTab = useNewsStore((state) => state.selectedTab);

  // activeTab이 변경될 때마다 뉴스 데이터를 가져오고 currentIndex를 0으로 설정
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getNewsLandingPage(activeTab);
        setNewsData(data);
        setCurrentIndex(0); // 탭이 변경될 때 첫 번째 아이템을 보여주기 위해 currentIndex 초기화
      } catch (error) {
        console.error("뉴스 조회 중 에러 발생:", error);
      }
    };

    fetchNews();
  }, [activeTab]); // activeTab이 변경될 때마다 실행

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % newsData.length;
    setFadeState("fade-out");

    setTimeout(() => {
      setCurrentIndex(newIndex);
      setFadeState("fade-in");
    }, 300);
  };

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + newsData.length) % newsData.length;
    setFadeState("fade-out");

    setTimeout(() => {
      setCurrentIndex(newIndex);
      setFadeState("fade-in");
    }, 300);
  };

  useEffect(() => {
    setActiveTab("Lawvax소식");
    setSelectedTab("Lawvax소식");
  }, []);

  return (
    <div className="news-section">
      <div className="title">
        <div>
          <p>
            <span className="blue">N</span>
            EWS
            <Link to={`/news?category=${selectedTab}`}>
              <button className="more">
                <img src={img.more} alt="More button" />
              </button>
            </Link>
          </p>
        </div>
      </div>

      <div className="theme-select">
        <button
          onClick={() => {
            setActiveTab("Lawvax소식");
            setSelectedTab("Lawvax소식");
          }}
        >
          <span className={activeTab === "Lawvax소식" ? "active" : ""}>
            Lawvax소식
          </span>
        </button>
        <button
          onClick={() => {
            setActiveTab("인재영입");
            setSelectedTab("인재영입");
          }}
        >
          <span className={activeTab === "인재영입" ? "active" : ""}>
            인재영입
          </span>
        </button>
      </div>

      <div className="content">
        <div className="img-section-wrap">
          <div className="img-section">
            <img
              src={
                newsData[currentIndex]?.mainImg === "null" ||
                newsData[currentIndex]?.mainImg === null
                  ? activeTab === "Lawvax소식"
                    ? img.defaultNews
                    : activeTab === "언론보도"
                      ? img.defaultMedia
                      : activeTab === "인재영입"
                        ? img.defaultComer
                        : img.defaultNews
                  : newsData[currentIndex]?.mainImg
              }
              alt={newsData[currentIndex]?.title}
            />
          </div>
        </div>
        <div className="text-section">
          <div className="nav">
            <button onClick={handlePrev}>
              <img src={img.icons.ArrowLeft} alt="Previous" />
            </button>
            <span>
              {currentIndex + 1} / {newsData.length}
            </span>
            <button onClick={handleNext}>
              <img src={img.icons.ArrowRight} alt="Next" />
            </button>
          </div>
          <div className={`text-wrap ${fadeState}`}>
            {newsData.length > 0 && (
              <>
                <Link to={`/news/post/${newsData[currentIndex].id}`}>
                  <p className="title">{newsData[currentIndex].title}</p>
                  <p className="content">{newsData[currentIndex].summary}</p>
                  <button className="more">자세히 보기</button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
