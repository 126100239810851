import more from "../../assets/images/more.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import img from "../../assets/images/img";
import { getMediaLandingPage } from "../../api/mediaLandingPage";
import { formatDateToDotSeparated } from "../../utils/dateFormat";
import { swiperPerViewCount } from "../../utils/swiperPerViewCount";

const MediaSection = () => {
  interface MediaItem {
    id: number;
    title: string;
    link: string;
    workField: string;
    media_company: string;
    mediaDate: string;
  }

  const [mediaData, setMediaData] = useState<MediaItem[]>([]);
  const [isMediaLoading, setIsMediaLoading] = useState(true);

  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const data = await getMediaLandingPage();
        setMediaData(data);
        setIsMediaLoading(false);
      } catch (error) {
        console.error("뉴스 조회 중 에러 발생:", error);
      }
    };

    fetchNews();
  }, []);

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <section className="newsletter-section">
      <div className="title">
        <div>
          <p>
            <span className="blue">M</span>EDIA
            <Link to="/news">
              <button className="more">
                <img src={more} alt="" />
              </button>
            </Link>
          </p>
        </div>
      </div>
      {!isMediaLoading && (
        <div className="swiper-container">
          <button className="nav-button prev" onClick={handlePrev}>
            <img src={img.icons.ArrowLeft} alt="Previous Slide" />
          </button>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            slidesPerView={1}
            spaceBetween={10}
            scrollbar={{ draggable: true, dragSize: 300 }}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            onSwiper={(swiper: any) => (swiperRef.current = swiper)}
            breakpoints={{
              300: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: swiperPerViewCount(mediaData.length, 4),
              },
            }}
          >
            {mediaData.length > 0 &&
              mediaData.map((item, index) => (
                <SwiperSlide key={index}>
                  <Link to={item.link} target="_blank">
                    <div className="newsletter-content">
                      <div className="mark">
                        <span>{item.workField}</span>
                      </div>
                      <div className="title">
                        <span>{item.title}</span>
                      </div>
                      <div className="bottom">
                        <span className="date">{item.media_company}</span>
                        <span>{formatDateToDotSeparated(item.mediaDate)}</span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
          <button className="nav-button next" onClick={handleNext}>
            <img src={img.icons.ArrowRight} alt="Next Slide" />
          </button>
        </div>
      )}
    </section>
  );
};

export default MediaSection;
