import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import CheckBox from "../../../components/Admin/Common/CheckBox";
import DropdownSrc from "../../../assets/images/ic_admin_dropdowArr.svg";
import { OPINION_REGISTER_TEAM } from "../../../const/admin";
import {
  createOpinion,
  getOpinionItem,
  updateOpinion,
} from "../../../api/admin";

export interface OpinionContent {
  title: string;
  team: string;
  articleDate: string;
  link: string;
  isVisible: boolean;
}

export default function OpinionRegister() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [isLoading, setIsLoading] = useState(false);
  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [contents, setContents] = useState<OpinionContent>({
    title: "",
    link: "",
    team: "",
    articleDate: today,
    isVisible: true,
  });
  const dropdownRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    (async () => {
      if (state?.id) {
        const { data } = await getOpinionItem(Number(state?.id));
        setContents(data);
      }
    })();
  }, [state?.id]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsTeamOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSingleCheck = () => {
    setContents({ ...contents, isVisible: !contents.isVisible });
  };

  const dropDownPlaceHolder = (list: string): string => {
    if (list === "") {
      return "선택";
    }
    return `${list}`;
  };

  const handleTeamCheck = (team: string) => {
    setContents({
      ...contents,
      team: team,
    });
    setIsTeamOpen(false);
  };

  const onClickSubmitButton = async () => {
    try {
      setIsLoading(true);
      const combinedData = {
        ...contents,
      };
      if (window.confirm("법률정보를 등록하시겠습니까?")) {
        if (state?.id) {
          await updateOpinion(state?.id, combinedData);
        } else {
          await createOpinion(combinedData);
        }
        navigate("/admin/opinion-management");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="admin-common-container">
      <h2 className="admin-common-title">법률정보 작성</h2>
      <div className="admin-post-registerCon">
        <div className="admin-post-btnLine">
          <div className="admin-media-inputWrap">
            <p>카테고리</p>
            <input type="text" value="법률정보" disabled readOnly />
          </div>
          <div className="admin-media-inputWrap">
            <p>팀</p>
            <button
              type="button"
              className="admin-post-selBtn"
              onClick={() => setIsTeamOpen(!isTeamOpen)}
            >
              <p>{dropDownPlaceHolder(contents.team)}</p>
              <img src={DropdownSrc} alt="드롭다운 아이콘" />
            </button>
            <div>
              {isTeamOpen && (
                <ul className="admin-media-dropList" ref={dropdownRef}>
                  {OPINION_REGISTER_TEAM.map((team) => (
                    <div key={`team-${team}`}>
                      <li className="admin-media-dropItem">
                        <label className="admin-custom-checkbox">
                          <input
                            type="checkbox"
                            onClick={() => handleTeamCheck(team)}
                            checked={contents.team === team}
                            readOnly
                          />
                          <span className="admin-checkmark"></span>
                          <p>{team}</p>
                        </label>
                      </li>
                    </div>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="admin-media-inputWrap">
            <p>기사날짜</p>
            <input
              type="date"
              value={contents.articleDate || today}
              onChange={(e) => {
                setContents({ ...contents, articleDate: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="admin-post-inputWrap" style={{ marginTop: "40px" }}>
          <p>제목</p>
          <input
            type="text"
            value={contents.title}
            onChange={(e) =>
              setContents({ ...contents, title: e.target.value })
            }
            placeholder="제목을 입력하세요"
          />
        </div>

        <div className="admin-post-inputWrap">
          <p>링크</p>
          <input
            type="text"
            value={contents.link}
            onChange={(e) => setContents({ ...contents, link: e.target.value })}
            placeholder="링크를 입력하세요"
          />
        </div>

        <div className="admin-media-emtpyWrap"></div>

        <div className="admin-register-btnWrap">
          <button
            type="button"
            disabled={isLoading}
            onClick={onClickSubmitButton}
            className="admin-register-savebtn"
          >
            등록
          </button>
          <CheckBox
            isVisible={contents.isVisible}
            label="비공개"
            handleSingleCheck={handleSingleCheck}
          />
        </div>
      </div>
    </section>
  );
}
