import { useState, useEffect } from "react";

import CategoryTab from "../../../components/Admin/Common/CategoryTab";
import { OPINION_TAB_CONTENT } from "../../../const/admin";
import OpinionContents from "../../../components/Admin/Opinion/OpinionContents";
import {
  getOpinionList,
  getOpinionCount,
  deleteOpinion,
} from "../../../api/admin";
import useUpdateUrlWithQuery from "../../../utils/useUpdateUrlWithQuery";

const DEFAULT = "전체";
const DEFAULT_PAGE = 1;

export default function OpinionManagement() {
  const [opinionTab, setOpinionTab] = useState(OPINION_TAB_CONTENT);
  const [opinionTotalCount, setOpinionTotalCount] = useState(0);
  const [opinionList, setOpinionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
  const [selectTeam, setSelectTeam] = useState(
    Object.keys(opinionTab)[0] || DEFAULT
  );
  const [searchValue, setSearchValue] = useState("");
  const updateUrlWithQuery = useUpdateUrlWithQuery();

  useEffect(() => {
    (async () => {
      const {
        data: { totalCount, opinionList },
      } = await getOpinionList(currentPage, selectTeam, searchValue);
      const { data } = await getOpinionCount();

      setOpinionTab(data);
      setOpinionTotalCount(totalCount);
      setOpinionList(opinionList);
    })();
  }, [selectTeam, currentPage]);

  const handlePosition = (team: string) => {
    setSelectTeam(team);
  };

  const handleSearch = async (e: any) => {
    if (e.key === "Enter") {
      setCurrentPage(DEFAULT_PAGE);
      const {
        data: { totalCount, opinionList },
      } = await getOpinionList(currentPage, selectTeam, searchValue);

      searchValue === ""
        ? updateUrlWithQuery([], true)
        : updateUrlWithQuery([
            { paramsKey: "search", paramsValue: searchValue },
          ]);

      setOpinionTotalCount(totalCount);
      setOpinionList(opinionList);
    }
  };

  const onClickDeleteButton = async (id: number) => {
    if (window.confirm("삭제하시겠습니까?")) {
      await deleteOpinion(id);
      const {
        data: { totalCount, opinionList },
      } = await getOpinionList(currentPage, selectTeam, searchValue);
      const { data } = await getOpinionCount();

      setOpinionTab(data);
      setOpinionTotalCount(totalCount);
      setOpinionList(opinionList);
    }
  };

  return (
    <section className="admin-common-container">
      <h2 className="admin-common-title">법률정보 관리</h2>
      <div className="admin-writing-wrapper">
        <CategoryTab
          list={opinionTab}
          onClick={handlePosition}
          selectCategory={selectTeam}
        />
        <OpinionContents
          totalCount={opinionTotalCount}
          list={opinionList}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handleSearch={handleSearch}
          setSearchValue={setSearchValue}
          onClickDeleteButton={onClickDeleteButton}
        />
      </div>
    </section>
  );
}
